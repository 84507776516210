@use "sass:math";

.mod_article.article--vpn {
    margin-bottom: 0;
    position: relative;

    @include media-breakpoint-up(lg) {
        padding-bottom: remX(110px);
    }

    > .container > .container__inner {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            display: block;
            position: relative;
        }
    }

    .ce_text:not(.text--lead) {
        margin-bottom: remX(30px);

        @include media-breakpoint-up(lg) {
            margin-bottom: remX(56px);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .text--lead {
        @include media-breakpoint-up(md) {
            margin-bottom: remX(55px);
        }
    }

    .ce_image {
        margin: remX(62px) #{$grid-gutter * -1} 0;
        order: 1;

        @include media-breakpoint-up(lg) {
            position: absolute;
            bottom: -390px;
            right: math.div($grid-gutter, -2);
            margin: 0;
            max-width: 380px;
        }

        @media (min-width: 1300px) {
            max-width: 430px;
            right: -50px;
        }

        .image_container {
            margin: 0;

            @include media-breakpoint-up(lg) {
                width: 100%;
                height: 100%;
            }
        }

        img {
            width: auto;

            @include media-breakpoint-up(lg) {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
