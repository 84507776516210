.quote {
    margin-bottom: remX(40px);

    @include media-breakpoint-up(md) {
        margin-bottom: remX(52px);
    }

    &__container {
        position: relative;
        background-color: #f3f3f3;

        @include media-breakpoint-up(md) {
            display: flex;
        }

        // Reduce the news details left column offset
        .news-full & {
            @include media-breakpoint-up(md) {
                margin-left: -44%;
            }
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            bottom: -8px;
            left: 50%;
            max-width: 966px;
            width: 100%;
            height: 13px;
            background: url('../images/news-list-shadow.png') no-repeat center/contain;
            transform: translateX(-50%);
            z-index: -1;
        }
    }

    &__image {
        margin: 0;
        min-width: 300px;

        // Expand the image in news details so it's aligned to the content above and below
        .news-full & {
            min-width: 30.61%;

            img {
                width: 100%;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: remX(35px) remX(25px) remX(30px);
        color: $gray-color;

        @include media-breakpoint-up(md) {
            padding: remX(30px) remX(52px) remX(10px);
        }
    }

    &__text {
        margin-bottom: remX(22px);
        font-size: remX(20px);
        font-style: italic;
        font-weight: $font-weight-semi-bold;
        line-height: calc(30 / 20);

        @include media-breakpoint-up(lg) {
            margin-bottom: remX(20px);
            font-size: remX(24px);
            line-height: calc(36 / 24);
        }
    }

    &__author {
        margin: 0;
        font-size: 14px;
        font-style: italic;
    }

    h2 {
        margin-bottom: remX(40px);
    }
}
