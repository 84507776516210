@use "sass:math";

.article--products-top {
    > .container > .container__inner {
        @extend .offset-xl-1;
        @extend .col-xl-10;
    }

    h1 {
        margin-bottom: remX(46px);

        + p {
            font-size: remX(22px);
            line-height: math.div(30, 22);
        }
    }
}

.article--products {
    margin-top: remX(-22px);
    overflow: hidden;

    @include media-breakpoint-up(md) {
        padding-bottom: remX(100px);
    }

    .ce_text {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            display: block;
            position: relative;
            padding-top: 0;
        }

        &:not(:last-child) {
            margin-bottom: 85px;

            @include media-breakpoint-up(lg) {
                margin-bottom: remX(155px);
            }
        }

        &:nth-child(even) {
            @include media-breakpoint-up(md) {
                margin-left: 23%;
            }

            @include media-breakpoint-up(lg) {
                margin-left: 38%;
            }

            .image_container {
                @include media-breakpoint-up(md) {
                    right: calc(100% + 30px);
                }

                @include media-breakpoint-up(lg) {
                    right: calc(100% + 100px);
                }
            }
        }

        &:nth-child(odd) {
            .image_container {
                @include media-breakpoint-up(md) {
                    left: calc(100% + 15px);
                }

                @include media-breakpoint-up(lg) {
                    left: calc(100% + 60px);
                }
            }
        }

        .image_container {
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 0;
            overflow: hidden;
            pointer-events: none;
            order: -1;

            @include media-breakpoint-up(md) {
                position: absolute;
                top: 0;
                width: auto;
                display: block;
                z-index: -1;
            }

            img {
                max-width: 100%;
                max-height: 350px;
                width: auto;

                @include media-breakpoint-up(md) {
                    max-width: none;
                    max-height: none;
                }
            }
        }

        p {
            &:nth-child(3) {
                display: none;

                @include media-breakpoint-up(md) {
                    display: block;
                }
            }

            &:last-of-type {
                text-align: center;

                @include media-breakpoint-up(md) {
                    text-align: left;
                }

                a:only-child {
                    @extend .link-btn;
                    margin-top: remX(25px);
                    padding-left: 55px;
                    padding-right: 55px;
                    background-image: none;
                    font-size: remX(14px);
                }
            }
        }
    }
}
