.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: remX(20px);
    border-top: 1px solid #d8dee4;
    color: #b2bec9;
    letter-spacing: 0.1em;
    font-size: 10px;
    font-weight: $font-weight-bold;

    p {
        display: flex;
        align-items: center;
        margin: 0;
        text-transform: uppercase;

        &::after {
            content: '';
            margin: 0 7px 0 14px;
            display: block;
            width: 23px;
            height: 1px;
            background-color: #b2bec9;
        }
    }

    ul {
        @extend %reset;
        display: flex;
        align-items: center;
    }

    li {
        @extend %reset;

        &:not(:last-child) {
            margin-left: 7px;
            margin-right: 7px;
        }

        &.previous {
            margin-right: 30px;
        }

        &.next {
            margin-left: 30px;
        }
    }

    a {
        color: $primary-color;
        text-decoration: none;
        transition: color $transition-time;

        &:hover {
            color: $light-blue-color;
            text-decoration: none;
        }
    }

    strong {
        color: inherit;
        font-weight: inherit;
    }

    i {
        font-size: 10px;
    }
}
