// News navigation
.news-navigation {
    margin-bottom: remX(65px);
    padding-bottom: 2px;
    border-bottom: 1px solid #d8dee4;
    font-size: remX(16px);
    font-weight: $font-weight-semi-bold;
    letter-spacing: 0.025rem;

    @include media-breakpoint-down(sm) {
        overflow-y: scroll;
        white-space: nowrap;
    }

    ul {
        @extend %reset;
        display: flex;
    }

    li {
        @extend %reset;

        &:not(:last-child) {
            margin-right: remX(35px);
        }
    }

    a {
        color: $gray-color;
        text-decoration: none;
        transition: color $transition-time;

        &:hover {
            color: $light-blue-color;
        }
    }

    a.trail,
    strong {
        border-bottom: 3px solid $light-blue-color;
        color: $light-blue-color;
    }
}

// News reader
.mod_newsreader p.back {
    display: none;
}

// Featured news list
.newslist-featured {
    padding-left: $grid-gutter * 0.5;
    padding-right: $grid-gutter * 0.5;

    @include media-breakpoint-up(md) {
        padding-left: 0;
        padding-right: 0;
    }

    .empty {
        display: none;
    }
}

// Regular news list
.newslist-regular {
    padding-left: $grid-gutter * 0.5;
    padding-right: $grid-gutter * 0.5;

    @include media-breakpoint-up(md) {
        padding-left: 0;
        padding-right: 0;
    }
}

// News archive badge
.news-archive-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 53px;
    height: 53px;
    background-color: $primary-color;
    border-radius: 50%;
    color: #fff;
    line-height: 1;
    font-size: remX(32px);

    &--3 {
        background-color: #f3718e;
        @include icon($icon-projects);
    }

    &--4 {
        background-color: #647bff;
        @include icon($icon-information);
    }

    &--5 {
        background-color: #f6a97c;
        font-size: remX(26px);
        @include icon($icon-about);
    }
}

// News full
.news-full {
    &__sidebar {
        @extend .col-md-4;
        margin-top: remX(34px);
        margin-bottom: remX(80px);
        order: 1;

        @include media-breakpoint-up(md) {
            width: 30%;
            margin-top: 0;
            margin-bottom: 0;
            order: 0;
        }
    }

    &__content {
        @extend .col-md-8;

        @include media-breakpoint-up(md) {
            width: 70%;
        }
    }

    &__mobile-only {
        @include media-breakpoint-up(md) {
            display: none !important;
        }
    }

    &__desktop-only {
        @include media-breakpoint-down(sm) {
            display: none !important;
        }
    }

    &__text {
        margin-top: remX(32px);

        @include media-breakpoint-up(md) {
            margin-top: 0;
        }

        .ce_text:first-child p:first-child {
            color: $primary-color;
            font-size: remX(18px);
            font-weight: $font-weight-semi-bold;
            line-height: calc(30 / 18);
        }
    }

    &__tags {
        margin-bottom: remX(20px);
        color: $primary-color;
        font-size: remX(14px);
        font-weight: $font-weight-bold;
        line-height: calc(24 / 14);

        @include media-breakpoint-up(md) {
            margin-bottom: remX(40px);
        }
    }

    &__tag {
        &:not(:last-child) {
            margin-right: 1rem;
        }
    }

    h1 {
        margin-bottom: remX(25px);
        font-size: remX(30px);
        font-weight: $font-weight-semi-bold;

        + .ce_text {
            font-size: remX(18px);
            font-weight: $font-weight-semi-bold;
            line-height: calc(30 / 18);
        }
    }

    h2 {
        margin-top: remX(60px);
        margin-bottom: remX(30px);
        font-size: remX(20px);
        font-weight: $font-weight-semi-bold;

        @include media-breakpoint-up(md) {
            margin-top: remX(86px);
        }
    }

    .ce_text {
        max-width: 100%;
    }

    .info {
        margin-bottom: remX(34px);
        font-size: remX(14px);
    }

    .sharebuttons {
        font-size: remX(14px);

        ul {
            @extend %reset;
        }

        li {
            @extend %reset;

            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }

        a {
            display: inline-flex;
            align-items: center;
            color: $primary-color;
            text-decoration: none;

            &:hover {
                color: $blue-color;
            }

            &::before {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 7px;
                width: 28px;
                height: 28px;
                border-radius: 50%;
                color: #fff;
            }

            &.twitter {
                @include icon($icon-twitter);

                &::before {
                    background-color: #55acee;
                }
            }

            &.linkedin {
                @include icon($icon-linkedin);

                &::before {
                    background-color: #0e76a8;
                }
            }

            span {
                @include link-underline($blue-color, $primary-color, 1.2em);
                line-height: 1.5;
            }
        }
    }

    .related-news {
        margin-top: remX(68px);

        h3 {
            margin-bottom: remX(25px);
            font-size: remX(18px);
            line-height: 1.2;

            @include media-breakpoint-up(lg) {
                margin-right: 5em; // force a line break
            }
        }
    }
}

// News latest
.news-latest {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: remX(30px);
    background-color: #f3f3f3;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: remX(70px);
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: -8px;
        left: 50%;
        max-width: 966px;
        width: 100%;
        height: 13px;
        background: url('../images/news-list-shadow.png') no-repeat center/contain;
        transform: translateX(-50%);
        z-index: -1;
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: remX(50px) remX(30px) remX(20px) remX(40px);

        @include media-breakpoint-up(lg) {
            padding: remX(68px) remX(70px) remX(58px) remX(50px);
        }
    }

    &__badge {
        position: absolute;
        bottom: remX(20px);
        right: remX(30px);
    }

    .image_container {
        margin: 0;
        min-width: 50%;

        a {
            display: block;
            height: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .ce_text {
        max-width: 100%;
    }

    .info {
        margin-bottom: remX(10px);
        font-size: remX(14px);
    }

    .more {
        margin-top: auto;
        margin-bottom: 0;

        a {
            display: inline-block;
            border-bottom: 2px solid $blue-color;
            font-size: remX(12px);
            font-weight: $font-weight-bold;
            letter-spacing: .05em;
            text-decoration: none;
            text-transform: uppercase;
            transition: all $transition-time;

            &:hover {
                border-color: $primary-color;
                color: $primary-color;
            }
        }
    }

    h2 {
        margin-bottom: remX(30px);
        font-size: remX(30px);
        line-height: 1.2;

        a {
            color: $primary-color;
            text-decoration: none;
            transition: color $transition-time;

            &:hover {
                color: $light-blue-color;
            }
        }
    }
}

// News simple
.news-simple {
    font-size: remX(14px);
    line-height: calc(24 / 14);

    &:not(:last-child) {
        margin-bottom: remX(24px);
    }

    a {
        @include link-underline($primary-color, $blue-color);
        text-decoration: none;

        &:hover {
            color: $primary-color;
        }
    }
}

// News short
.news-short {
    margin-bottom: remX(30px);

    @include media-breakpoint-up(lg) {
        margin-bottom: remX(50px);
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: remX(52px) remX(40px) remX(20px) remX(40px);
        background-color: #f3f3f3;

        @include media-breakpoint-up(lg) {
            padding: remX(50px) remX(40px) remX(20px) remX(40px);
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            bottom: -8px;
            left: 50%;
            max-width: 309px;
            width: 100%;
            height: 13px;
            background: url('../images/news-list-shadow.png') no-repeat center/contain;
            transform: translateX(-50%);
            z-index: -1;
        }
    }

    &__badge {
        position: absolute;
        bottom: remX(20px);
        right: remX(30px);
    }

    .more {
        margin-top: auto;
        margin-bottom: 0;

        a {
            display: inline-block;
            border-bottom: 2px solid $blue-color;
            font-size: remX(12px);
            font-weight: $font-weight-bold;
            text-decoration: none;
            text-transform: uppercase;
            transition: all $transition-time;

            &:hover {
                border-color: $primary-color;
                color: $primary-color;
            }
        }
    }

    h3 {
        margin-bottom: remX(56px);
        font-size: remX(18px);
        line-height: 1.2;

        a {
            color: $primary-color;
            text-decoration: none;
            transition: color $transition-time;

            &:hover {
                color: $light-blue-color;
            }
        }
    }
}

// News slider
.news-slider {
    display: flex;
    width: 275px;
    height: auto;
    box-sizing: border-box;

    @include media-breakpoint-up(md) {
        width: 300px;
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: remX(70px) remX(10px) remX(20px) remX(40px);
        background-color: #f3f3f3;

        @include media-breakpoint-up(lg) {
            padding: remX(70px) remX(40px) remX(20px);
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            bottom: -8px;
            left: 50%;
            max-width: 309px;
            width: 100%;
            height: 13px;
            background: url('../images/news-list-shadow.png') no-repeat center/contain;
            transform: translateX(-50%);
            z-index: -1;
        }
    }

    .more {
        margin-top: auto;
        margin-bottom: 0;

        a {
            display: inline-block;
            border-bottom: 2px solid $blue-color;
            font-size: remX(12px);
            font-weight: $font-weight-bold;
            text-decoration: none;
            text-transform: uppercase;
            letter-spacing: .05em;
            transition: all $transition-time;

            &:hover {
                border-color: $primary-color;
                color: $primary-color;
            }
        }
    }

    h3 {
        margin-bottom: remX(36px);
        font-size: remX(18px);
        line-height: 1.2;

        @include media-breakpoint-up(md) {
            margin-bottom: remX(40px);
        }

        a {
            color: $primary-color;
            text-decoration: none;
            transition: color $transition-time;

            &:hover {
                color: $light-blue-color;
            }
        }
    }
}
