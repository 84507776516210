.mod_article.article--news-slider {
    position: relative;
    padding: remX(78px) 0 remX(380px);
    color: #fff;

    @include media-breakpoint-up(md) {
        padding: remX(83px) 0 remX(378px);
    }

    &::before {
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: remX(176px);
        left: 0;
        background: $blue-color;
        z-index: -1;

        @include media-breakpoint-up(md) {
            bottom: remX(180px);
        }
    }

    .text--lead {
        h2, p {
            color: inherit;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    .mod_newslist {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        .swiper-slide {
            display: flex;
            flex-wrap: wrap;
            height: auto;
        }

        .swiper-pagination-container {
            width: 100%;

            @include media-breakpoint-up(md) {
                display: flex;
                justify-content: center;
            }
        }

        .swiper-pagination {
            display: flex;
            width: 100%;
            margin-top: remX(64px);
            padding-left: $grid-gutter * 0.5;
            padding-right: $grid-gutter * 0.5;
            height: 2px;

            @include media-breakpoint-up(md) {
                margin-left: calc(50% - #{remX(340px)});
                padding-left: 0;
                padding-right: 0;
                max-width: remX(680px);
            }

            &-lock {
                opacity: 0;
                pointer-events: none;
            }

            &-bullet {
                display: block;
                width: 100%;
                height: 100%;
                background-color: #dee1e4;
                cursor: pointer;

                &-active {
                    background-color: $primary-color;
                }
            }
        }
    }
}
