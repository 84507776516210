form a {
    @include link-underline($light-blue-color, #fff);
}

label {
    display: block;
}

select,
textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'] {
    width: 100%;
    padding: 20px 10px 7px;
    background-color: transparent;
    border: 1px solid #1a3e5d;
    border-radius: 5px;
    box-shadow: none;
    color: #fff;
    font-family: $font-family-body;
    font-size: remX(16px);
    font-weight: $font-weight-semi-bold;
    line-height: 1;
    transition: all $transition-time;

    &:focus {
        border-color: $light-blue-color;
        outline: none;
    }

    &.error {
        border-color: red;
        outline: none;
    }
}

input[type='checkbox'],
input[type='radio'] {
    margin: 0;
}

textarea {
    display: block;
    line-height: 1.2;
    resize: vertical;
    height: remX(275px);
}

.ce_form .formbody {
    .widget {
        margin-bottom: remX(9px);

        > label,
        > fieldset > legend {
            display: block;
            margin-bottom: 0.2rem;
            font-weight: $font-weight-regular;
            color: #fff;
            font-size: remX(16px);

            span.mandatory {
                margin-left: 2px;
                color: inherit;
            }
        }
    }

    .widget-upload {
        > label {
            position: relative;
            padding-left: 15px;
            color: $light-blue-color;
            text-decoration: underline;
            cursor: pointer;
            transition: color $transition-time;

            &::before {
                content: '+';
                position: absolute;
                left: 0;
            }

            &:hover {
                color: #fff;
            }
        }

        input[type='file'] {
            @extend .sr-only;
        }
    }

    .widget-radio {
        margin-bottom: remX(14px);
        margin-left: remX(10px);

        @include media-breakpoint-up(md) {
            margin-left: 0;
        }
    }

    .widget-checkbox {
        @include media-breakpoint-up(md) {
            margin-left: -8px;
        }
    }

    .widget-text,
    .widget-textarea {
        position: relative;

        @include media-breakpoint-up(md) {
            margin-left: -10px;
            margin-right: 10px;
        }

        label {
            position: absolute;
            top: 20px;
            left: 11px; // horizontal padding of input + border width
            line-height: 1;
            color: #fff;
            pointer-events: none;
            transition: all $transition-time;
        }

        &.error {
            padding-bottom: 2rem;

            p.error {
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }

        input::placeholder,
        textarea::placeholder {
            opacity: 0;
        }

        input:focus + label,
        input:not(:placeholder-shown) + label,
        textarea:focus + label,
        textarea:not(:placeholder-shown) + label {
            top: 8px;
            color: $light-blue-color;
            font-size: 8px;
            font-weight: $font-weight-semi-bold;
        }
    }

    p.error {
        margin: 0;
        color: red;
        font-size: remX(14px);
        font-weight: $font-weight-semi-bold;
    }

    .widget-upload {
        margin-top: remX(25px);
        margin-bottom: remX(48px);
    }

    .widget-submit {
        margin-top: remX(32px);
        margin-left: 0;

        @include media-breakpoint-up(md) {
            margin-left: -12px;
        }
    }

    .radio_container,
    .checkbox_container {
        margin: 0;
        padding: 0;
        border: 0;

        > span {
            display: flex;
            align-items: center;
        }

        label {
            cursor: pointer;
        }
    }

    .checkbox_container {
        input[type="checkbox"] {
            position: absolute;
            opacity: 0;

            &:checked + label::after {
                opacity: 1;
            }
        }

        > span:not(:last-child) {
            margin-right: remX(36px);
        }

        label {
            position: relative;
            padding-left: 22px;
            font-size: remX(14px);

            &::before {
                content: '';
                position: absolute;
                top: 5px;
                left: 0;
                display: block;
                width: 14px;
                height: 14px;
                border: 1px solid #fff;
            }

            &::after {
                content: '';
                position: absolute;
                top: 9px;
                left: 4px;
                display: block;
                width: 6px;
                height: 6px;
                background-color: $light-blue-color;
                opacity: 0;
                transition: opacity $transition-time;
            }
        }
    }

    .radio_container {
        display: flex;
        flex-wrap: wrap;

        input[type="radio"] {
            position: absolute;
            opacity: 0;

            &:checked + label::after {
                opacity: 1;
            }
        }

        > span:not(:last-child) {
            margin-right: remX(36px);
        }

        label {
            position: relative;
            padding-left: 22px;

            &::before {
                content: '';
                position: absolute;
                top: 7px;
                left: 0;
                display: block;
                width: 14px;
                height: 14px;
                margin-right: 8px;
                border: 1px solid #fff;
                border-radius: 50%;
            }

            &::after {
                content: '';
                position: absolute;
                top: 11px;
                left: 4px;
                display: block;
                width: 6px;
                height: 6px;
                background-color: $light-blue-color;
                border-radius: 50%;
                opacity: 0;
                transition: opacity $transition-time;
            }
        }
    }

    .widget.wrap .radio_container {
        flex-direction: column;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
    }

    .captcha {
        width: 75px;
        margin-right: 8px;
        text-align: center;
    }

    .captcha_text {
        font-weight: bold;
    }

    .submit {
        display: inline-block;
        padding: 12px 38px 11px;
        border: none;
        border-radius: 37px;
        background-color: $light-blue-color;
        color: #fff;
        font-family: $font-family-body;
        font-size: remX(14px);
        font-weight: $font-weight-extra-bold;
        line-height: 1;
        text-decoration: none;
        cursor: pointer;
        transition: background-color $transition-time;

        &:hover {
            background-color: $blue-color;
        }
    }
}

.ce_form form {
    transition: opacity $transition-time;

    &.ajax-loading {
        cursor: progress;
        opacity: 0.5;

        .formbody {
            pointer-events: none;
        }
    }

    .ajaxconfirm {
        color: $light-blue-color;
        font-size: remX(20px);
        font-weight: $font-weight-bold;

        *:last-child {
            margin-bottom: 0;
        }
    }
}
