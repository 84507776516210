// Miscellaneous
@use "sass:math";

@use "sass:list";

$mobile-header-height: 88px;
$transition-time: .15s;

// Colors
$primary-color: #00284b;
$gray-color: #5c687a;
$blue-color: #00a0ff;
$light-blue-color: #15c8ff;

// Grid / layout
$grid-columns: 12;
$grid-gutter: 20px;
$vr: remX(30px);

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1222px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1200px,
);

// Typings
$body-color: $gray-color;
$font-size-desktop: 16px;
$font-family-body: 'Nunito', sans-serif;
$font-family-headings: 'Nunito', sans-serif;
$font-weight-regular: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

/* Mobile menu */
$mobile-menu-bg: none;
$mobile-menu-font-size: $font-size-desktop;
$mobile-menu-padding: 1rem;

$mobile-menu-item-bg: rgba(0, 0, 0, 0.01);
$mobile-menu-item-border-color: rgba(0, 0, 0, 0.05);
$mobile-menu-item-border-width: 1px;
$mobile-menu-item-padding-y: 1.2rem;
$mobile-menu-item-padding-x: 1.2rem;
$mobile-menu-item-hover-bg: rgba(0, 0, 0, 0.02);

$mobile-menu-item-active-bg: $primary-color;
$mobile-menu-item-active-color: #fff;

$mobile-menu-submenu-font-size: 0.95em;
$mobile-menu-submenu-icon-active-bg: rgba(0, 0, 0, 0.01);
$mobile-menu-submenu-icon-border-color: rgba(255, 255, 255, 0.1);
$mobile-menu-submenu-icon-width: 50px;

$mobile-menu-trigger-bg: transparent;
$mobile-menu-trigger-color: $primary-color;
$mobile-menu-trigger-gap: 6px;
$mobile-menu-trigger-height: 2px;
$mobile-menu-trigger-padding: 0;
$mobile-menu-trigger-space: 7px;
$mobile-menu-trigger-width: 30px;

// Functions
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
    $max: map-get($breakpoints, $name);
    @return if($max and $max > 0, $max - .02, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
    @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
    $n: index($breakpoint-names, $name);
    @if not $n {
        @error "breakpoint `#{$name}` not found in `#{$breakpoints}`";
    }
    @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Mixins
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);

    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
    $max: breakpoint-max($name, $breakpoints);
    @if $max {
        @media (max-width: $max) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
    $min:  breakpoint-min($name, $breakpoints);
    $next: breakpoint-next($name, $breakpoints);
    $max:  breakpoint-max($next);

    @if $min != null and $max != null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    } @else if $max == null {
        @include media-breakpoint-up($name, $breakpoints) {
            @content;
        }
    } @else if $min == null {
        @include media-breakpoint-down($next, $breakpoints) {
            @content;
        }
    }
}

/* Link underline */
@mixin link-underline($hoverColor: currentColor, $color: currentColor, $spacing: 1.25em, $size: 1px) {
    background: linear-gradient(to right, #{$color} 0%, #{$color} 100%) 100% list.slash($spacing, 100%) #{$size} no-repeat;
    color: $color;
    text-decoration: none;

    @if ($color != $hoverColor) {
        transition: background-image $transition-time, color $transition-time;
    }

    &:hover,
    &.active {
        text-decoration: none;

        @if ($color != $hoverColor) {
            color: $hoverColor;
            background-image: linear-gradient(to right, $hoverColor 0%, $hoverColor 100%);
        }
    }
}

/* Add icon */
@mixin icon($icon: '', $position: before, $margin: null, $font-size: null, $color: null) {
    &::#{$position} {
        @if $icon {
            font-weight: normal;
            font-family: 'icomoon' !important;
            font-style: normal;
            font-variant: normal;
            line-height: 1;
            text-transform: none;
            content: $icon;
            speak: none;

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        @if $margin {
            @if ($position == before) {
                margin-right: $margin;
            }

            @if ($position == after) {
                margin-left: $margin;
            }
        }

        @if $font-size {
            font-size: $font-size;
        }

        @if $color {
            color: $color;
        }
    }
}

// Functions
@function remX($size) {
    @return math.div($size, $font-size-desktop) * 1rem;
}

// Helper classes
%reset {
    margin: 0;
    padding: 0;
    list-style: none;
}
