.fusion {
    position: fixed;
    inset: 0;
    z-index: 1000;
    background: rgba(#3A4A60, .8);

    &__inside {
        position: absolute;
        inset: 50% auto auto 50%;
        min-width: 300px;
        max-width: 400px;
        padding: 20px;
        font-size: 18px;
        line-height: 1.3;
        font-weight: bold;
        color: #fff;
        background: #2F588E;
        transform: translate(-50%, -50%);
    }

    &__close {
        position: absolute;
        inset: 15px 15px auto auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        background: rgba(17, 17, 17, 0.5);
        border: none;
        border-radius: 50%;
        cursor: pointer;

        svg {
            width: 16px;
            height: 16px;
            fill: rgba(255,255,255,.7);
        }
    }

    &__alert {
        font-size: 32px;
        margin-bottom: 10px;
    }

    &__title {
        font-size: 22px;
        font-weight: bold;
    }

    &__text {
        margin: 15px 0;
    }

    &__link {
        display: inline-block;
        padding: 10px 30px;
        color: #fff;
        text-decoration: none;
        border: 1px solid #fff;
        border-radius: 3px;
        transition: background-color 0.1s ease-in-out;

        &:hover {
            background-color: rgba(255,255,255,.1);
        }
    }
}
