@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?ixsxrd') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?ixsxrd') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?ixsxrd##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-wlan {
  &:before {
    content: $icon-wlan; 
  }
}
.icon-network {
  &:before {
    content: $icon-network; 
  }
}
.icon-consultation {
  &:before {
    content: $icon-consultation; 
  }
}
.icon-monitoring {
  &:before {
    content: $icon-monitoring; 
  }
}
.icon-about {
  &:before {
    content: $icon-about; 
  }
}
.icon-information {
  &:before {
    content: $icon-information; 
  }
}
.icon-projects {
  &:before {
    content: $icon-projects; 
  }
}
.icon-teamviewer {
  &:before {
    content: $icon-teamviewer; 
  }
}
.icon-team {
  &:before {
    content: $icon-team; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-support {
  &:before {
    content: $icon-support; 
  }
}

