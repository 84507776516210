.mod_article.article--consultation {
    position: relative;
    padding-top: remX(130px);

    @include media-breakpoint-up(md) {
        padding-top: remX(110px);
    }

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url('../images/consultation-bg.svg') center/600px auto;
        pointer-events: none;
        z-index: -1;

        @include media-breakpoint-up(md) {
            width: 72.5%;
        }
    }

    > .container > .container__inner {
        @extend .offset-xl-1;
        padding: remX(348px) $grid-gutter 0;
        background-color: #fff;

        @include media-breakpoint-down(sm) {
            margin-left: $grid-gutter * -1;
            margin-right: $grid-gutter * -1;
        }

        @include media-breakpoint-up(md) {
            padding: remX(80px) remX(90px) remX(55px) remX(102px);
        }
    }

    .ce_text {
        position: relative;
        margin-bottom: remX(68px);
        max-width: 100%;

        @include media-breakpoint-up(sm) {
            margin-bottom: remX(54px);
            max-width: remX(420px);
        }

        > *:not(.image_container) {
            position: relative;
            z-index: 1;
        }

        .image_container {
            margin: 0;
            position: absolute;
            bottom: calc(100% + 13px);
            left: calc(50% - 147px);

            @include media-breakpoint-up(md) {
                bottom: auto;
                top: -5px;
                left: calc(100% + -73px);
            }

            @include media-breakpoint-up(lg) {
                top: -43px;
                left: calc(100% + -64px);
            }

            @include media-breakpoint-up(xl) {
                left: calc(100% + 47px);
            }

            img {
                width: 294px;

                @include media-breakpoint-up(md) {
                    width: auto;
                    min-width: 252px;
                }

                @include media-breakpoint-up(lg) {
                    min-width: 352px;
                }
            }
        }
    }

    .ce_hyperlink a {
        @extend .link-btn;
        padding-left: 43px;
        padding-right: 43px;
        font-size: remX(14px);
    }
}
