@use "sass:math";

.footer {
    margin-top: 150px;

    &__inside {
        @extend .container;

        > .row > * {
            margin-bottom: 1rem;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }
    }

    &__bottom {
        margin: remX(60px) 0 remX(10px);
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: remX(12px);
        font-weight: $font-weight-semi-bold;

        @include media-breakpoint-up(md) {
            margin-top: remX(76px);
            flex-direction: row;
            justify-content: center;
        }

        > * {
            @include media-breakpoint-up(md) {
                margin-left: remX(30px);
                margin-right: remX(30px);
            }

            @include media-breakpoint-up(lg) {
                margin-left: remX(60px);
                margin-right: remX(60px);
            }
        }
    }

    &__logo {
        display: block;
        margin-bottom: 60px;
        width: 160px;

        @include media-breakpoint-up(md) {
            margin-bottom: remX(60px);
            width: 124px;
        }
    }

    &__newsletter {
        margin-bottom: remX(60px);
        color: $primary-color;
        font-size: remX(16px);
        font-weight: $font-weight-semi-bold;
        line-height: calc(24 / 16);

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            margin-left: remX(42px);
        }

        @include media-breakpoint-up(xl) {
            padding-right: remX(100px);
        }

        form {
            transition: opacity $transition-time;

            &.ajax-loading {
                cursor: wait;
                opacity: .5;

                .formbody {
                    pointer-events: none;
                }
            }
        }

        .widget-explanation *:last-child {
            margin-bottom: 0;
        }

        .widget-text {
            margin-bottom: remX(32px);

            label {
                @extend .sr-only;
            }
        }

        input.text {
            padding-top: remX(22px);
            padding-bottom: 2px;
            padding-left: 0;
            border-color: $primary-color;
            border-style: solid;
            border-width: 0 0 1px 0;
            border-radius: 0;
            color: $primary-color;
            font-size: remX(16px);
            line-height: calc(24 / 16);
            transition: border-color $transition-time;

            &::placeholder {
                color: $gray-color;
                font-weight: 300;
                font-style: italic;
            }

            &:focus {
                border-color: $blue-color;
            }
        }

        button.submit {
            @extend .link-btn;
            background-color: $gray-color;
            border: none;
            cursor: pointer;
            font-family: $font-family-body;

            &:hover {
                background-color: $blue-color;
            }
        }

        p.error {
            margin-bottom: 0;
            margin-top: 1rem;
            color: red;
            font-weight: $font-weight-bold;
            font-size: remX(15px);
        }

        .ajaxconfirm {
            color: $blue-color;
            font-weight: $font-weight-bold;

            *:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__navigation {
        margin-top: 9px;
        color: $primary-color;
        font-weight: $font-weight-bold;
        font-size: remX(14px);
        line-height: calc(30 / 14);
        letter-spacing: 0.025rem;

        ul {
            @extend %reset;
        }

        li {
            @extend %reset;

            &:not(:last-child) {
                margin-bottom: remX(10px);

                @include media-breakpoint-up(md) {
                    margin-bottom: 0;
                }
            }
        }

        h4 {
            margin-bottom: remX(16px);
            font-size: remX(20px);
            font-weight: inherit;

            @include media-breakpoint-up(md) {
                margin-bottom: remX(6px);
            }
        }

        a, strong {
            color: inherit;
            text-decoration: none;
            transition: color $transition-time;
        }

        a:hover, strong {
            color: $light-blue-color;
        }

        &--custom {
            font-size: remX(20px);

            @include media-breakpoint-only(xs) {
                margin-top: remX(-18px);
            }

            @include media-breakpoint-only(sm) {
                margin-top: 0;
            }

            ul {
                @include media-breakpoint-only(sm) {
                    display: flex;
                    flex-wrap: wrap;
                    margin-left: math.div($grid-gutter, -2);
                    margin-right: math.div($grid-gutter, -2);
                }
            }

            li {
                @include media-breakpoint-only(sm) {
                    margin-bottom: 1rem;
                    padding-left: $grid-gutter * 0.5;
                    padding-right: $grid-gutter * 0.5;
                    width: 50%;
                }

                &:not(:last-child) {
                    margin-bottom: remX(5px);

                    @include media-breakpoint-up(md) {
                        margin-bottom: remX(38px);
                    }
                }
            }
        }
    }

    &__copyright {
        text-align: center;
    }

    &__copyright-navigation {
        ul {
            @extend %reset;
            display: flex;
        }

        li {
            @extend %reset;

            &:not(:last-child)::after {
                content: '|';
                margin: 0 10px;
            }
        }

        a, strong {
            color: $primary-color;
            text-decoration: underline;
        }

        a:hover, strong {
            color: $light-blue-color;
        }
    }
}
