$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-twitter: "\ea96";
$icon-linkedin: "\eaca";
$icon-wlan: "\e90c";
$icon-network: "\e90d";
$icon-consultation: "\e90e";
$icon-monitoring: "\e90f";
$icon-about: "\e909";
$icon-information: "\e90a";
$icon-projects: "\e90b";
$icon-teamviewer: "\e908";
$icon-team: "\e907";
$icon-chevron-down: "\e900";
$icon-chevron-left: "\e901";
$icon-chevron-right: "\e902";
$icon-chevron-up: "\e903";
$icon-phone: "\e904";
$icon-search: "\e905";
$icon-support: "\e906";

