.article--home-desktop {
    display: none;
    margin-top: remX(-220px);
    margin-bottom: remX(107px) !important;

    @include media-breakpoint-up(lg) {
        display: block;
    }

    .container__inner {
        margin-left: 0 !important;
    }

    .ce_text {
        max-width: none;
    }

    h1 {
        position: relative;
        margin-bottom: 0;
        font-size: remX(60px);
        line-height: remX(60px);
        font-weight: $font-weight-black;
        text-align: center;
        z-index: 1;

        strong {
            color: $light-blue-color;
            font-weight: $font-weight-black;
        }
    }
}
