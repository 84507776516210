@import "defaults";

html {
    box-sizing: border-box;
    font-size: $font-size-desktop;
}

*, *:before, *:after {
    box-sizing: inherit;
}

body, form, figure, blockquote {
    margin: 0;
    padding: 0
}

body {
    padding-top: calc(#{$mobile-header-height} + 220px);

    &.mobile-menu-active {
        overflow: hidden;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 0;

        &.mobile-menu-active {
            overflow: auto;
        }
    }
}

img, svg, iframe {
    display: block;
    max-width: 100%;
    border: 0;
}

img, svg {
    height: auto;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

hr {
    height: 1px;
    margin: 3.5em 0;
    border: none;
    background-color: $body-color;

    & + .ce_text {
        margin-top: -1em;
    }
}

header, footer, main, nav, section, aside, article, figure, figcaption {
    display: block
}

html, body, form, fieldset, p, div, h1, h2, h3, h4, h5, h6 {
    -webkit-text-size-adjust: none;
}

body, input, textarea, select {
    font-family: $font-family-body;
    font-weight: $font-weight-regular;
    line-height: 1.875;
    color: $body-color;
}

/* Vertical rhythm */
ul, ol, dd,
p, figure,
pre, table, fieldset {
    margin-top: 0;
    margin-bottom: $vr;
}

select, input, textarea {
    font-size: 99%;
}

strong {
    color: $primary-color;
    font-weight: $font-weight-bold;
}

a {
    color: $blue-color;
    text-decoration: underline;
    transition: color .15s;
}

h1, h2, h3, h4, h5, h6 {
    color: $primary-color;
    font-family: $font-family-headings;
    font-size: inherit;
    line-height: inherit;
    font-weight: $font-weight-bold;
    margin: 0;
}

h1, .h1 {
    margin-bottom: remX(27px);
    font-size: 46px;
    line-height: calc(68 / 60);

    @include media-breakpoint-up(md) {
        margin-bottom: remX(45px);
        font-size: remX(60px);
    }
}

h2, .h2 {
    margin-bottom: remX(32px);
    font-size: remX(35px);
    font-weight: $font-weight-semi-bold;
    line-height: calc(68 / 60);

    @include media-breakpoint-up(md) {
        margin-bottom: remX(18px);
        font-size: remX(48px);
    }
}

h3, .h3 {
    margin-bottom: 0;
    font-size: remX(20px);
    font-weight: $font-weight-bold;
}

h4, .h4 {
    margin-bottom: remX(6px);
    font-size: remX(18px);

    @include media-breakpoint-up(md) {
        margin-bottom: remX(10px);
    }
}

figcaption {
    padding: 5px 0;
    font-size: 14px;
    color: $body-color;
    font-style: italic;
    text-align: left;
    letter-spacing: 0.02em;
}

.clear, #clear {
    height: .1px;
    font-size: .1px;
    line-height: .1px;
    clear: both
}

.invisible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important; // Fix for https://github.com/twbs/bootstrap/issues/25686
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;

    @include media-breakpoint-up(md) {
        padding-left: $grid-gutter * 0.5;
        padding-right: $grid-gutter * 0.5;
    }
}

.ce_text {
    max-width: remX(536px);

    table {
        border-collapse: collapse;
        border-spacing: 0;

        td,
        th {
            padding: 0;

            &:first-child {
                padding-right: 1rem;
            }
        }
    }

    a {
        @include link-underline($primary-color, $blue-color);
    }

    a[href^="tel:"] {
        display: inline-flex;
        background :none;
        align-items: center;
        @extend strong;
        @include icon($icon-phone, before, 5px, 1.6em, 23px);

        &:hover {
            color: $light-blue-color;
        }

        &::after {
            display: none;
        }
    }
}

.text--center {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.text--lead {
    h1 {
        margin-bottom: remX(35px);

        @include media-breakpoint-up(md) {
            margin-bottom: remX(15px);
        }
    }

    h1 + p,
    h2 + p,
    p:first-child {
        margin-bottom: remX(32px);
        color: $primary-color;
        font-size: remX(18px);
        font-weight: $font-weight-semi-bold;
        line-height: calc(30 / 18);

        @include media-breakpoint-up(md) {
            margin-bottom: remX(42px);
            font-size: remX(20px);
            line-height: calc(36 / 20);
        }
    }
}

.text--blue-box {
    max-width: remX(580px);
    margin-top: remX(40px);
    margin-left: $grid-gutter * -1;
    margin-right: $grid-gutter;
    padding: remX(45px) remX(16px) remX(45px) remX(20px);
    background-color: $blue-color;
    color: #fff;
    font-size: 20px;
    font-weight: $font-weight-semi-bold;
    line-height: calc(30 / 20);

    @include media-breakpoint-up(md) {
        margin-top: remX(55px);
        margin-left: remX(-44px);
        margin-right: 0;
        padding: remX(64px) remX(46px) remX(68px) remX(44px);
        font-size: remX(22px);
        line-height: calc(30 / 22);
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.link-btn {
    display: inline-block;
    padding: 12px 50px 11px;
    line-height: 1;
    border-radius: 37px;
    background-color: $blue-color;
    color: #fff;
    font-weight: $font-weight-extra-bold;
    letter-spacing: 0.05em;
    text-align: center;
    text-decoration: none;
    transition: background-color $transition-time;

    &:hover {
        background-color: $primary-color;
    }
}

@each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
        %responsive-container-#{$breakpoint} {
            max-width: $container-max-width;
        }

        // Extend each breakpoint which is smaller or equal to the current breakpoint
        $extend-breakpoint: true;

        @each $name, $width in $grid-breakpoints {
            @if ($extend-breakpoint) {
                .container#{breakpoint-infix($name, $grid-breakpoints)} {
                    @extend %responsive-container-#{$breakpoint};
                }

                // Once the current breakpoint is reached, stop extending
                @if ($breakpoint == $name) {
                    $extend-breakpoint: false;
                }
            }
        }
    }
}
