.booknav {
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
        margin-top: remX(-50px); // make sure the content stays on the same page height no matter if booknav is there or not
        margin-bottom: remX(20px);
    }

    &__container {
        display: flex;
    }

    &__links {
        display: flex;
        margin-right: 13px;
    }

    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        background-color: #b2bec9;
        color: #fff;
        text-decoration: none;
        font-size: 8px;
        line-height: 1;

        &:not(:last-child) {
            margin-right: 2px;
        }

        &--active {
            transition: background-color $transition-time;

            &:hover {
                background-color: $blue-color;
            }
        }

        &--inactive {
            cursor: not-allowed;
        }
    }

    &__info {
        display: flex;
        align-items: center;
        color: $primary-color;
        font-size: 8px;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
    }

    &__count {
        position: relative;
        margin-right: 13px;
        padding-right: 37px;

        &::after {
            content: '';
            position: absolute;
            top: calc(50% - 0.5px);
            right: 0;
            display: block;
            height: 1px;
            width: 23px;
            background-color: $primary-color;
        }
    }

    &__title {
        letter-spacing: 0.1em;
    }
}
