@use "sass:math";

.timeline {
    .container > .container__inner {
        position: relative;

        @include media-breakpoint-up(xl) {
            margin-left: 12.2%;
            margin-right: 12.2%;
        }
    }

    &__wrapper {
        margin-top: remX(40px);
    }

    &__slider .swiper-container {
        padding-top: remX(28px);
    }

    &__entry {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
    }

    &__image {
        margin-bottom: remX(22px);
        padding-bottom: 10px;
        background: url('../images/timeline-shadow.png') no-repeat center bottom;

        img {
            width: auto;
        }
    }

    &__date {
        margin-bottom: remX(10px);
        color: $primary-color;
        font-size: remX(18px);
        font-weight: $font-weight-bold;
    }

    &__description {
        max-width: remX(580px);
        text-align: center;
    }

    &__years {
        position: relative;
        overflow: hidden;
        height: remX(115px);

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background-color: #b2bec9;
            pointer-events: none;
        }

        .swiper-container,
        .swiper-wrapper {
            height: 100%;
        }
    }

    &__year {
        $this: &;
        display: flex;
        align-items: center;
        justify-content: center;
        width: remX(250px);

        &--active #{$this}-label {
            font-size: remX(75px);

            @include media-breakpoint-up(lg) {
                font-size: remX(100px);
            }
        }

        &-label {
            position: relative;
            color: #e4e8ec;
            font-size: remX(50px);
            font-weight: $font-weight-black;
            line-height: calc(68 / 60);
            transition: font-size $transition-time;

            @include media-breakpoint-up(lg) {
                font-size: remX(60px);
            }
        }

        &-month {
            position: absolute;
            top: calc(50% - 3px);
            width: 7px;
            height: 7px;
            background-color: $primary-color;
            border-radius: 50%;
            transition: all $transition-time;

            &--active {
                transform: scale(2);
            }

            &:not(#{$this}-month--active) {
                cursor: pointer;

                &:hover {
                    background-color: $blue-color;
                    transform: scale(2);
                }
            }

            @for $i from 0 through 12 {
                &--#{$i} {
                    left: percentage(math.div($i, 12));
                }
            }
        }
    }

    .swiper-button {
        position: absolute;
        top: remX(-76px);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 37px;
        height: 37px;
        background-color: $light-blue-color;
        border-radius: 50%;
        color: #fff;
        font-size: 10px;
        cursor: pointer;
        transition: background-color $transition-time;

        &:not(.swiper-button-disabled):hover {
            background-color: $blue-color;
        }

        &-prev {
            left: 0;

            @include media-breakpoint-up(xl) {
                left: auto;
                right: calc(100% + 8px);
            }
        }

        &-next {
            right: 0;

            @include media-breakpoint-up(xl) {
                left: calc(100% + 8px);
                right: auto;
            }
        }

        &-disabled {
            background-color: #b2bec9;
            cursor: not-allowed;
        }
    }
}
