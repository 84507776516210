// @see https://github.com/twbs/bootstrap/blob/main/scss/mixins/_grid.scss

@use "sass:math";

@mixin make-col($breakpoint, $columns) {
    $infix: breakpoint-infix($breakpoint);

    @include media-breakpoint-up($breakpoint) {
        .col#{$infix} {
            flex: 1 0 0;
        }

        .col#{$infix}-#{$columns} {
            flex: 0 0 auto;
            width: percentage(math.div($columns, $grid-columns));
        }
    }
}

@mixin make-offset($breakpoint, $columns) {
    $infix: breakpoint-infix($breakpoint);

    @include media-breakpoint-up($breakpoint) {
        @if not ($infix == "" and $columns == 0) {
            .offset#{$infix}-#{$columns} {
                $num: math.div($columns, $grid-columns);
                margin-left: if($num == 0, 0, percentage($num));
            }
        }
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: math.div($grid-gutter, -2);
    margin-left: math.div($grid-gutter, -2);

    > * {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: $grid-gutter * 0.5;
        padding-left: $grid-gutter * 0.5;
    }
}

@include make-col(xs, 6);
@include make-col(sm, 6);

@include make-col(md, 2);
@include make-col(md, 3);
@include make-col(md, 4);
@include make-col(md, 5);
@include make-col(md, 8);

@include make-col(xl, 2);
@include make-col(xl, 5);
@include make-col(xl, 10);

@include make-offset(xs, 6);
@include make-offset(sm, 0);
@include make-offset(md, 1);
@include make-offset(xl, 1);
