.mod_search {
    max-width: remX(780px);
    margin: 0 auto;

    .formbody {
        position: relative;
        margin-bottom: remX(74px);

        @include media-breakpoint-up(md) {
            margin-bottom: remX(70px);
        }
    }

    .widget-text {
        input {
            padding: 0 remX(60px) 0 remX(26px); // padding-right = button width
            height: remX(43px);
            font-size: 1rem;
            line-height: remX(43px);
            width: 100%;
            background-color: #fff;
            border: none;
            border-radius: remX(43px);
            box-shadow: 0 2px 7px 0 rgba(2, 4, 0, 0.4);
            color: $body-color;
            text-decoration: none;
            transition: color $transition-time;
            -webkit-appearance: none;

            &:focus {
                outline: none;
            }
        }
    }

    .widget-submit {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            padding: 0;
            width: remX(60px);
            height: 100%;
            background: none;
            border: none;
            border-radius: 0;
            color: $primary-color;
            font-size: remX(23px);
            cursor: pointer;
            -webkit-appearance: none;
            @include icon($icon-search);
            transition: color $transition-time;

            &::before {
                text-indent: 0;
            }

            &:hover {
                background: none;
                color: $light-blue-color;
            }
        }
    }

    .search-header {
        margin-bottom: remX(30px);
        padding-bottom: 5px;
        border-bottom: 1px solid #d8dee4;
        font-size: remX(12px);
        font-style: italic;

        @include media-breakpoint-up(md) {
            margin-bottom: remX(20px);
        }
    }

    .search-result {
        &:not(:last-child) {
            margin-bottom: remX(55px);
        }

        &__context {
            margin-bottom: 0;
        }

        &__headline {
            @include media-breakpoint-down(sm) {
                line-height: 1.3;
            }

            a {
                color: $primary-color;
                text-decoration: none;
                transition: color $transition-time;

                &:hover {
                    color: $light-blue-color;
                    text-decoration: none;
                }
            }
        }

        &__url {
            word-break: break-word;

            a {
                @include link-underline($primary-color, $blue-color);
            }
        }
    }
}
