.team {
    $this: &;
    max-width: remX(880px);
    margin-top: 0;

    @include media-breakpoint-up(md) {
        margin-top: remX(-30px);
    }

    &__person {
        margin-bottom: remX(40px);
        color: inherit;
        text-decoration: none;

        @include media-breakpoint-up(md) {
            margin-bottom: remX(57px);
        }

        &:hover {
            #{$this}__image img {
                transform: scale(1.1);
            }

            #{$this}__name,
            #{$this}__position {
                color: $primary-color;
            }
        }
    }

    &__image {
        margin-bottom: remX(16px);
        overflow: hidden;

        @include media-breakpoint-up(md) {
            margin-bottom: remX(14px);
        }

        img {
            width: 100%;
            transition: transform $transition-time;
        }
    }

    &__name {
        margin-bottom: 6px;
        font-size: remX(18px);
        color: $blue-color;
        transition: color $transition-time;

        @include media-breakpoint-up(md) {
            margin-bottom: 3px;
            font-size: remX(24px);
        }
    }

    &__position {
        font-size: remX(14px);
        line-height: calc(18 / 14);
        transition: color $transition-time;
    }
}
