.cta-box {
    $this: &;
    margin-top: remX(30px);
    max-width: remX(245px);
    font-size: remX(14px);
    line-height: calc(26 / 14);

    @include media-breakpoint-down(md) {
        margin-left: auto;
        margin-right: auto;
    }

    @include media-breakpoint-up(md) {
        margin-top: 10px;
    }

    &--left {
        display: flex;
        align-items: flex-start;
        max-width: 350px;
        margin-top: 20px;

        #{$this}__icon {
            min-width: remX(60px);
            margin-top: 5px;
            margin-right: remX(40px);
            font-size: remX(60px);

            .icon-wlan {
                font-size: remX(50px);
            }
        }

        #{$this}__content {
            margin-top: 18px;
            align-items: flex-start;
            text-align: left;
        }

        #{$this}__headline {
            margin-bottom: remX(12px);
            line-height: 1.2;
        }

        #{$this}__description {
            padding-right: 1rem; // force some line breaks
        }

        #{$this}__link .link-btn {
            padding-left: 43px;
            padding-right: 43px;
        }
    }

    &__icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: remX(31px);
        color: $blue-color;
        font-size: remX(48px);
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    &__headline {
        margin-bottom: remX(10px);
        color: $blue-color;
        font-size: remX(16px);
        font-weight: $font-weight-bold;
    }

    &__link {
        margin-top: remX(31px);

        a {
            min-width: remX(180px);
        }
    }
}
