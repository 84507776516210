.mod_article.article--partners {
    > .container > .container__inner {
        @extend .offset-xl-1;
        @extend .col-xl-10;

        @include media-breakpoint-up(lg) {
            padding: 30px 30px 40px;
            background-color: #f0f2f4;
            border-radius: 14px;
        }
    }

    .ce_gallery {
        .swiper-pagination {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: remX(40px);

            &-lock {
                display: none;
            }

            &-bullet {
                display: block;
                width: 10px;
                height: 10px;
                margin-left: 8px;
                margin-right: 8px;
                border-radius: 50%;
                background-color: #b2bec9;
                cursor: pointer;

                &-active {
                    background-color: $primary-color;
                }
            }
        }

        .swiper-slide {
            display: flex;
            justify-content: center;
            box-sizing: border-box;
        }

        h4 {
            position: relative;
            margin-bottom: 40px;
            font-size: 12px;
            font-weight: $font-weight-extra-bold;
            text-transform: uppercase;
            text-align: center;

            &::after {
                content: '';
                position: relative;
                left: calc(50% - 10px);
                bottom: 0;
                display: block;
                width: 20px;
                height: 4px;
                border-radius: 2px;
                background-color: $primary-color;

                @include media-breakpoint-up(lg) {
                    margin-top: 3px;
                }
            }
        }

        .image_container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 140px;
            height: 40px;
            margin: 0;
            padding: 0 #{$grid-gutter * 0.5};

            @include media-breakpoint-up(lg) {
                padding-left: $grid-gutter;
                padding-right: $grid-gutter;
            }
        }

        img {
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
}
