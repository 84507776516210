.jobs {
    $this: &;
    max-width: remX(536px);
    margin-top: -5px;
    margin-bottom: remX(5px);

    @include media-breakpoint-up(md) {
        margin-top: remX(12px);
        margin-bottom: 0;
    }

    &__job {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: remX(20px) 0;
        text-decoration: none;

        &:not(:last-child) {
            border-bottom: 1px solid #d8dee4;
        }

        &:hover {
            #{$this}__name {
                color: $primary-color;
            }

            i {
                opacity: 1;
            }
        }

        i {
            color: $primary-color;
            opacity: 0;
            transition: opacity $transition-time;
        }
    }

    &__content {
        line-height: 1.5;

        @include media-breakpoint-up(md) {
            line-height: 1.2;
        }
    }

    &__name {
        margin-bottom: remX(6px);
        font-size: remX(18px);
        font-weight: $font-weight-bold;
        transition: color $transition-time;
    }

    &__position {
        color: $body-color;
        font-size: remX(16px);
    }

    &__text {
        margin-top: remX(54px);

        a {
            @include link-underline($light-blue-color, $primary-color);
            font-weight: $font-weight-bold;
        }
    }
}
