.mod_article.article--networks {
    > .container > .container__inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include media-breakpoint-up(md) {
            justify-content: flex-start;
            max-width: remX(776px);
        }
    }

    .ce_text {
        margin-bottom: remX(55px);
        width: 100%;
        max-width: 100%;

        @include media-breakpoint-down(md) {
            &:last-child {
                margin-bottom: 0;
            }
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    .ce_hyperlink {
        display: flex;
        justify-content: center;
        width: 100%;

        @include media-breakpoint-up(md) {
            display: block;
            width: auto;
        }

        &:not(:last-child) {
            margin-bottom: remX(12px);

            @include media-breakpoint-up(md) {
                margin-right: remX(20px);
                margin-bottom: 0;
            }
        }

        a {
            display: inline-flex;
            justify-content: center;
            width: remX(234px);
            padding: 10px 10px 9px;
            line-height: 1;
            border: 2px solid $blue-color;
            border-radius: 36px;
            background-color: #fff;
            color: $blue-color;
            font-size: remX(14px);
            text-align: center;
            font-weight: $font-weight-extra-bold;
            text-decoration: none;
            letter-spacing: 0.05em;
            transition: all $transition-time;

            @include media-breakpoint-up(md) {
                width: remX(180px);
            }

            &:hover {
                background-color: $blue-color;
                color: #fff;
            }
        }
    }
}
