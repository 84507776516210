.article--home-mobile {
    margin-top: -150px;
    margin-bottom: 95px !important;

    @include media-breakpoint-up(lg) {
        display: none;
    }

    .container {
        padding-left: 0;
        padding-right: 0;
    }

    .ce_text {
        max-width: none;
        padding-left: $grid-gutter;
        padding-right: $grid-gutter;

        > *:last-child {
            margin-bottom: 0;
        }
    }

    .ce_text:nth-of-type(1) {
        margin-bottom: 45px;
        overflow: hidden;

        h1 {
            margin-bottom: 32px;
            font-size: 60px;
            line-height: 60px;
            font-weight: $font-weight-black;

            strong {
                color: $light-blue-color;
                font-weight: $font-weight-black;
            }

            + p {
                font-size: 20px;
                line-height: calc(30 / 20);
            }
        }

        p:last-child {
            display: flex;
            justify-content: center;
            margin-top: 42px;

            a:only-child {
                width: 37px;
                height: 37px;
                background-color: $light-blue-color;
                border-radius: 50%;
                font-size: 30px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                @include icon($icon-chevron-down, before, 0, 8px, #fff);

                &::before {
                    animation: scrollDown 2s ease infinite;
                }
            }
        }
    }

    .ce_text:nth-of-type(2) {
        position: relative;
        overflow: hidden;
        padding-top: 285px;
        padding-bottom: 95px;
        background-color: $primary-color;
        color: #fff;

        > *:not(.image_container) {
            position: relative;
            z-index: 1;
        }

        .image_container {
            position: absolute;
            top: 55px;
            right: -30px;
            width: auto;
            margin: 0;
            overflow: hidden;
            pointer-events: none;
            z-index: 0;

            img {
                max-width: none;
                max-height: 265px;
                width: auto;
            }
        }

        h2 {
            margin-top: 3px;
            font-size: 36px;
            color: inherit;
        }

        p {
            &:nth-of-type(1) {
                font-size: remX(18px);
                font-weight: $font-weight-semi-bold;
                line-height: calc(30 / 18);
            }

            &:last-of-type {
                text-align: center;

                a:only-child {
                    @extend .link-btn;
                    margin-top: remX(25px);
                    padding-left: 55px;
                    padding-right: 55px;
                    background-image: none;
                    font-size: remX(14px);
                }
            }
        }
    }

    .ce_text:nth-of-type(3) {
        position: relative;
        overflow: hidden;
        padding-top: 324px;
        padding-bottom: 60px;
        background-color: $blue-color;
        color: #fff;

        > *:not(.image_container) {
            position: relative;
            z-index: 1;
        }

        .image_container {
            position: absolute;
            top: 55px;
            left: -65px;
            width: auto;
            margin: 0;
            overflow: hidden;
            pointer-events: none;
            z-index: 0;

            img {
                max-width: none;
                max-height: 295px;
                width: auto;
            }
        }

        h2 {
            color: inherit;
            font-size: 36px;
        }

        p {
            &:nth-of-type(1) {
                font-size: remX(18px);
                font-weight: $font-weight-semi-bold;
                line-height: calc(30 / 18);
            }

            &:last-of-type {
                text-align: center;

                a:only-child {
                    @extend .link-btn;
                    margin-top: remX(25px);
                    padding-left: 55px;
                    padding-right: 55px;
                    background-color: $light-blue-color;
                    background-image: none;
                    font-size: remX(14px);
                }
            }
        }
    }
}

@keyframes scrollDown {
    0% {
        transform: translateY(-15px);
        opacity: 0;
    }
    45% {
        transform: translateY(0px);
        opacity: 1;
    }
    65% {
        transform: translateY(-5px);
        opacity: 1;
    }
    100% {
        transform: translateY(15px);
        opacity: 0;
    }
}
