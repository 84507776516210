.mod_article.article--wireless-network {
    margin-bottom: remX(102px);
    overflow: hidden;
    color: #fff;

    @include media-breakpoint-up(sm) {
        position: relative;
        margin-top: remX(-52px);
        margin-bottom: remX(130px);
        padding-top: remX(440px);
    }

    .wireless-network {
        position: relative;
        padding-top: remX(70px);
        padding-bottom: remX(105px);

        @include media-breakpoint-up(md) {
            padding: remX(60px) remX(55px);
        }

        @include media-breakpoint-up(lg) {
            display: flex;
            padding-top: remX(82px);
            padding-bottom: remX(90px);
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: remX(-45px);
            bottom: 0;
            right: -1000em;
            background-color: $primary-color;
            border-radius: 14px;
            z-index: -1;
        }
    }

    .wireless-network-left {
        margin-bottom: remX(50px);

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            margin-right: remX(110px);
            min-width: 390px;
            width: 390px;
        }

        .text--lead h2 {
            margin-bottom: remX(34px);

            @include media-breakpoint-up(md) {
                margin-bottom: remX(26px);
            }
        }
    }

    .wireless-network-right {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    .cta-box {
        &:not(:last-child) {
            margin-bottom: remX(60px);

            @include media-breakpoint-up(lg) {
                margin-bottom: remX(80px);
            }
        }

        &__icon,
        &__headline {
            color: $light-blue-color;
        }

        &__link .link-btn {
            background-color: $light-blue-color;

            &:hover {
                background-color: $blue-color;
            }
        }
    }

    .ce_image {
        margin-left: $grid-gutter * -1;
        margin-right: $grid-gutter * -1;

        @include media-breakpoint-up(sm) {
            position: absolute;
            top: 0;
            left: 0;
            right: 50px;
            z-index: -1;
            margin-left: 0;
            margin-right: 0;
        }

        .image_container {
            margin: 0;
        }

        img {
            width: 100%;
        }
    }

    .ce_text {
        max-width: 100%;
    }

    .text--lead {
        h2 {
            margin-bottom: remX(28px);
            line-height: 1.2;
        }

        h2, p {
            color: #fff;
        }

        h2 + p {
            margin-bottom: remX(28px);
        }
    }
}
