.team-member {
    $this: &;
    margin-top: remX(-220px); // reduce the header distance to content completely

    @include media-breakpoint-up(lg) {
        margin-top: remX(-172px);
    }

    &__header {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            align-items: center;
            flex-direction: row;
        }
    }

    &__content {
        order: 1;

        @include media-breakpoint-up(lg) {
            width: remX(500px);
            min-width: remX(500px);
            padding-top: remX(175px);
            order: 0;
        }
    }

    &__image {
        margin: 0 ($grid-gutter * -1) remX(38px);
        order: 0;

        @include media-breakpoint-up(md) {
            margin-left: 0;
            margin-right: 0;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            margin-left: remX(55px);
        }

        @media (min-width: 1290px) {
            min-width: 530px;
            order: 1;
        }

        img {
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: auto;
            }

            @media (min-width: 1290px) {
                max-width: none;
            }
        }
    }

    &__name {
        margin-bottom: remX(14px);
    }

    &__position {
        margin-bottom: remX(50px);
        font-weight: $font-weight-semi-bold;

        @include media-breakpoint-up(md) {
            margin-bottom: remX(25px);
        }
    }

    &__intro *:last-child {
        margin-bottom: 0;
    }

    &__qualifications {
        margin-top: remX(65px);

        @include media-breakpoint-up(md) {
            margin-top: remX(40px);
        }

        @include media-breakpoint-up(lg) {
            max-width: remX(680px);
        }

        &-headline {
            margin-bottom: remX(25px);
            font-size: remX(30px);
            line-height: calc(36 / 30);
            font-weight: $font-weight-semi-bold;
        }

        li {
            margin-bottom: remX(10px);
        }
    }
}
