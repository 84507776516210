.hotspots {
    $this: &;
    position: relative;

    &__image {
        margin: 0;
    }

    &__overlay {
        position: absolute;
    }

    &__hotspot {
        position: absolute;

        &:hover {
            #{$this}__trigger {
                background-color: $primary-color;
                z-index: 2;

                &-circle {
                    top: 0;
                    left: 0;
                    border-width: 1px;
                    width: 100%;
                    height: 100%;
                }

                &-icon {
                    transform: rotate(315deg);

                    &::before {
                        left: calc(50% - 7px);
                        width: 14px;
                        height: 2px;
                    }

                    &::after {
                        top: calc(50% - 7px);
                        height: 14px;
                    }
                }
            }

            #{$this}__tooltip {
                pointer-events: auto;
                opacity: 1;
            }
        }

        &--right-anchor #{$this}__tooltip {
            left: auto;
            right: -8px;
            padding-left: 45px;
            padding-right: 65px;
        }
    }

    &__trigger {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background-color: rgba($primary-color, .5);
        transition: background-color $transition-time;

        &-circle {
            position: absolute;
            top: 7px;
            left: 7px;
            width: 20px;
            height: 20px;
            border: 2px solid #fff;
            border-radius: 50%;
            transition: all $transition-time;
        }

        &-icon {
            position: relative;
            transition: transform $transition-time;

            &::before,
            &::after {
                content: '';
                position: absolute;
                display: block;
                background-color: #fff;
            }

            &::before {
                top: calc(50% - 1px);
                left: calc(50% - 5px);
                width: 10px;
                height: 2px;
            }

            &::after {
                top: calc(50% - 5px);
                left: calc(50% - 1px);
                width: 2px;
                height: 10px;
            }
        }
    }

    &__tooltip {
        position: absolute;
        top: -8px;
        left: -8px;
        min-width: remX(360px);
        padding: 10px 45px 35px 65px;
        background-color: rgba($primary-color, .9);
        border-radius: 10px;
        color: #fff;
        opacity: 0;
        pointer-events: none;
        transition: opacity $transition-time;
        z-index: 1;
    }

    &__name {
        margin: 7px 0 10px;
        color: #fff;
        font-family: $font-family-headings;
        font-size: remX(24px);
        font-weight: $font-weight-bold;
        line-height: 1.2;
    }

    &__description {
        font-size: remX(14px);
        line-height: calc(26 / 14);
    }

    &__link {
        margin-top: remX(24px);

        .link-btn {
            padding-left: remX(44px);
            padding-right: remX(44px);
            background-color: $light-blue-color;
            font-size: remX(14px);

            &:hover {
                background-color: $blue-color;
            }
        }
    }

    + .ce_hyperlink {
        position: absolute;
        top: 300px;
        width: 200px;
        margin-left: -20px;

        @include media-breakpoint-up(xl) {
            width: 280px;
            margin-left: 0;
        }
    }
}
