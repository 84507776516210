.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 10;

    @include media-breakpoint-up(lg) {
        position: static;
        margin-bottom: remX(253px);
        background-color: transparent;
    }

    &__inside {
        @extend .container;
        display: flex;
        align-items: center;

        @include media-breakpoint-up(lg) {
            display: block;
            max-width: calc(980px + #{$grid-gutter});
        }
    }

    &__top {
        position: fixed;
        top: $mobile-header-height; // .header__main height
        left: 0;
        right: 0;
        padding: 4.5px $grid-gutter;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        color: $primary-color;
        font-size: 10px;
        font-weight: $font-weight-bold;
        letter-spacing: 0.05em;
        background-color: #d8dee4;
        opacity: 0;
        pointer-events: none;
        transition: opacity $transition-time;

        body.mobile-menu-active & {
            opacity: 1;
            pointer-events: auto;
        }

        @include media-breakpoint-up(lg) {
            position: static;
            width: auto;
            margin-top: remX(19px);
            margin-bottom: remX(45px);
            flex-wrap: nowrap;
            justify-content: flex-end;
            padding: 0;
            background-color: transparent;
            opacity: 1;
            pointer-events: auto;
        }

        > *:not(:last-child) {
            display: flex;
            align-items: center;

            &::after {
                content: '|';
                margin: 0 20px 0 18px;
            }
        }
    }

    &__hotline {
        @include icon($icon-phone, before, 4px, 13px);

        @include media-breakpoint-down(sm) {
            display: none !important;
            width: 100%;
        }

        @include media-breakpoint-down(lg) {
            display: block;
        }

        &-label {
            margin-right: 5px;
        }

        &-link {
            color: inherit;
            font-weight: $font-weight-extra-bold;
            text-decoration: none;
            transition: color $transition-time;

            &:hover {
                color: $light-blue-color;
            }
        }
    }

    &__top-navigation {
        ul {
            @extend %reset;
            display: flex;
        }

        li {
            @extend %reset;

            &:not(:last-child)::after {
                content: '|';
                margin-left: 18px;
                margin-right: 20px;
            }
        }

        a, strong {
            color: inherit;
            text-decoration: none;
            transition: color $transition-time;
        }

        a:hover, strong {
            color: $light-blue-color;
        }
    }

    &__search {
        &-link {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 44px;
            height: 24px;
            background-color: #fff;
            border: none;
            border-radius: 26px;
            box-shadow: 0 2px 7px 0 rgba(2, 4, 0, 0.4);
            color: $primary-color;
            text-decoration: none;
            cursor: pointer;
            transition: color $transition-time;
            @include icon($icon-search, before, null, 14px);

            &:hover {
                color: $light-blue-color;
            }
        }
    }

    &__main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: $mobile-header-height;
        width: 100%;

        @include media-breakpoint-up(lg) {
            height: auto;
            width: auto;
            align-items: flex-start;
        }
    }

    &__logo {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
            width: 124px;
            margin-top: 4px;
        }

        &-mobile {
            display: block;
            width: 26px;
            margin-right: auto;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }

    &__navigation {
        display: flex;
        justify-content: flex-end;
        margin-right: 25px;
        color: $primary-color;
        font-size: remX(14px);
        width: calc(100% - 200px); // leave some space for logo

        @include media-breakpoint-up(lg) {
            display: block;
            margin-right: 0;
        }

        ul {
            @extend %reset;
        }

        li {
            @extend %reset;
        }

        a, strong {
            color: inherit;
            font-weight: $font-weight-bold;
            letter-spacing: 0.025rem;
            text-decoration: none;
            transition: color $transition-time;
        }

        a:hover, strong:not(.trail) {
            color: $light-blue-color;
        }

        .level_1 {
            display: flex;
            justify-content: space-between;

            > li {
                padding-top: 2px;
                display: none;

                @include media-breakpoint-up(lg) {
                    display: block;
                }

                > a,
                > strong {
                    position: relative;

                    &::before {
                        content: '';
                        display: block;
                        width: 0;
                        height: 4px;
                        position: absolute;
                        top: calc(100% + 5px);
                        left: calc(50% - 9px);
                        border-radius: 4px;
                        background-color: $light-blue-color;
                        opacity: 0;
                        transition: all $transition-time;
                    }
                }

                > a:hover,
                > a.trail,
                > strong {
                    &::before {
                        width: 18px;
                        opacity: 1;
                    }
                }
            }

            > li.submenu {
                position: relative;

                > a,
                > strong {
                    display: flex;
                    align-items: center;
                    @include icon($icon-chevron-down, after, 5px, 5px);
                }

                &:hover {
                    &::after {
                        opacity: 1;
                    }

                    > .level_2 {
                        opacity: 1;
                        pointer-events: auto;
                    }
                }
            }

            > li.page-support {
                margin-left: 14px;
                padding-top: 1px;
                display: block;

                > a,
                > strong {
                    display: block;
                    padding: 10.5px 29px 8.5px;
                    line-height: 1;
                    border-radius: 32px;
                    background-color: $light-blue-color;
                    font-size: 14px;
                    font-weight: $font-weight-bold;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    transition: background-color $transition-time;

                    @include media-breakpoint-up(lg) {
                        padding: 7px 54px 5px;
                        border-radius: 26px;
                        font-size: remX(14px);
                    }

                    &::before {
                        display: none !important;
                    }
                }

                > a:hover,
                > strong {
                    background-color: $blue-color;
                    color: inherit;
                }
            }
        }

        .level_2 {
            position: absolute;
            top: calc(100% + 20px); // 20px of gap, see ::after
            padding: remX(15px) remX(25px);
            background-color: #fff;
            box-shadow: 0 0 9.3px 0.7px rgba(2, 4, 0, 0.2);
            border-radius: 10px;
            line-height: calc(45 / 14);
            z-index: 10;
            opacity: 0;
            pointer-events: none;
            transition: opacity $transition-time;

            // Top arrow
            &::before {
                content: '';
                position: absolute;
                left: 60px;
                bottom: 100%;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 6px 6px 6px;
                border-color: transparent transparent #ffffff transparent;
            }

            // Gap filler, so the dropdown menu doesn't disappear when hovering it
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 100%;
                width: 100%;
                height: 20px;
            }

            > li {
                white-space: nowrap;

                > a,
                > strong {
                    @include icon($icon-chevron-right, after, 19px, 9px);

                    &::after {
                        opacity: 0;
                        transition: opacity $transition-time;
                    }
                }

                > a.trail {
                    color: $light-blue-color;
                }

                > a:hover,
                > strong {
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__mobile-menu-trigger {
        $this: &;
        display: block;
        text-align: center;
        cursor: pointer;

        @include media-breakpoint-up(lg) {
            display: none;
        }

        &-inside {
            display: flex;
            align-items: center;
        }

        // Hamburger icon
        &-hamburger {
            width: $mobile-menu-trigger-width;
            padding: $mobile-menu-trigger-padding;
            vertical-align: middle;
            background: $mobile-menu-trigger-bg;

            &::after,
            &::before,
            i {
                display: block;
                height: $mobile-menu-trigger-height;
                margin: $mobile-menu-trigger-space 0;
                background-color: $mobile-menu-trigger-color;
                border-radius: 1px;
                transition: $transition-time;
                content: '';
            }

            &::before {
                margin-top: 0;
            }

            &::after {
                margin-bottom: 0;
            }

            &:not(:first-child) {
                margin-left: $mobile-menu-trigger-gap;
            }
        }

        body.mobile-menu-active & {
            #{$this}-hamburger {
                &::before {
                    transform: translateY($mobile-menu-trigger-height + $mobile-menu-trigger-space) rotate(135deg);
                }

                &::after {
                    transform: translateY(-($mobile-menu-trigger-height + $mobile-menu-trigger-space)) rotate(-135deg);
                }

                i {
                    transform: scale(0);
                }
            }
        }
    }

    &__mobile-menu {
        position: fixed;
        top: 121px; // height of the .header__main + .header__top
        left: 0;
        width: 100%;
        height: calc(100% - 115px);
        padding: 40px 10px 0 45px;
        background-color: #fff;
        opacity: 0;
        pointer-events: none;
        z-index: 10;
        transition: opacity $transition-time;

        @include media-breakpoint-up(lg) {
            display: none !important;
        }

        body.mobile-menu-active & {
            opacity: 1;
            pointer-events: auto;
        }

        &-inside {
            @extend .container;
            display: flex;
            height: 100%;
            overflow: auto;
            padding-left: 0;
            padding-right: 0;
        }

        &-navigation {
            ul {
                @extend %reset;
            }

            li {
                @extend %reset;
            }

            .level_1 {
                padding-bottom: 45px;

                > li {
                    &:not(:last-child) {
                        margin-bottom: 10px;
                    }

                    &.submenu-visible > .level_2 {
                        display: block;
                    }

                    > a,
                    > strong {
                        display: flex;
                        align-items: center;
                        color: $primary-color;
                        font-size: 20px;
                        font-weight: $font-weight-extra-bold;
                        line-height: 2;
                        text-decoration: none;

                        &.submenu {
                            @include icon($icon-chevron-down, after, 10px, 6px);
                        }
                    }

                    > a:hover,
                    > strong:not(.trail) {
                        color: $blue-color;
                    }
                }
            }

            .level_2 {
                display: none;
                margin: 10px 0 40px 15px;

                > li {
                    margin: 0 1rem;

                    > a,
                    > strong {
                        display: flex;
                        align-items: center;
                        color: $primary-color;
                        font-size: 14px;
                        font-weight: $font-weight-extra-bold;
                        line-height: calc(40 / 14);
                        text-decoration: none;
                    }

                    > a.trail,
                    > a:hover,
                    > strong {
                        @include icon($icon-chevron-right, after, 10px, 8.5px);
                        color: $blue-color;
                    }
                }
            }
        }
    }
}

.mod_pageimage {
    position: absolute;
    top: 78px;
    left: 0;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;

    @include media-breakpoint-up(md) {
        top: remX(150px);
        left: 50%;
        width: 50%;
    }

    body.page-support & {
        top: 2rem;
    }

    .image_container {
        margin: 0;
        display: flex;
        justify-content: flex-end;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    img {
        max-width: none;
        width: auto;
    }
}
