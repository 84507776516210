.mod_article {
    &:not(:last-child) {
        margin-bottom: remX(104px);

        @include media-breakpoint-up(lg) {
            margin-bottom: remX(144px);
        }
    }

    &.margin-small {
        margin-bottom: remX(58px);
    }

    &:first-child > .container > .container__inner > .ce_headline:first-child,
    .booknav + & > .container > .container__inner > .ce_headline:first-child {
        max-width: remX(580px); // same as .text--blue-box

        @include media-breakpoint-down(md) {
            text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
        }
    }

    > .container > .container__inner {
        @include media-breakpoint-up(xl) {
            margin-left: 12.2%;
        }
    }
}

.mod_article.article {
    // Article with call-to-action element
    &--call-to-action {
        > .container > .container__inner {
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(md) {
                align-items: center;
                justify-content: space-between;
                flex-direction: row;
                flex-wrap: wrap;

                > *:first-child {
                    width: 100%;
                }
            }

            @include media-breakpoint-down(lg) {
                .ce_text {
                    max-width: 25.5rem;
                }
            }

            @include media-breakpoint-up(xl) {
                .cta-box {
                    margin-top: 0;
                    margin-right: remX(140px);
                }
            }

            .ce_headline {
                max-width: remX(536px);
            }
        }
    }

    // Full width article without container indent
    &--full-width {
        > .container > .container__inner {
            margin-left: 0;
        }
    }

    // Article with news modules
    &--news {
        margin-bottom: remX(38px);

        @include media-breakpoint-up(md) {
            margin-bottom: remX(72px);
        }

        > .container > .container__inner {
            @extend .offset-xl-1;
            @extend .col-xl-10;
        }

        h1.ce_headline {
            @extend h2;
        }

        .text--lead {
            h1 {
                margin-bottom: remX(35px);

                @include media-breakpoint-up(md) {
                    margin-bottom: remX(49px);
                }
            }

            p {
                line-height: calc(30 / 20);

                @include media-breakpoint-down(sm) {
                    font-size: remX(20px);
                }
            }
        }
    }

    // Timeline
    &--timeline {
        padding-top: remX(80px);
        padding-bottom: remX(60px);
        background-color: #d8dee4;

        > .container {
            width: 100%;
            max-width: none;
            padding: 0;

            > .container__inner {
                margin-left: 0;
            }
        }
    }

    // Top article with reduced distance to the header
    &--top {
        margin-top: remX(-108px);

        @include media-breakpoint-up(md) {
            margin-top: remX(-122px);
        }
    }
}
