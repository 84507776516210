.mod_article.article--support-form {
    margin-top: remX(70px);
    overflow: hidden;
    color: #fff;

    > .container > .container__inner {
        position: relative;
        padding-top: remX(74px);
        padding-bottom: remX(95px);

        @include media-breakpoint-up(md) {
            padding: remX(60px) remX(55px);
        }

        @include media-breakpoint-up(lg) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-top: remX(82px);
            padding-bottom: remX(82px);
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: remX(-45px);
            bottom: 0;
            right: -1000em;
            background-color: $primary-color;
            border-radius: 14px;
            z-index: -1;
        }
    }

    .support-form-left {
        margin-bottom: remX(88px);
        padding-top: 8px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            min-width: 480px;
            width: 480px;
        }
    }

    .support-form-right {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }

        @include media-breakpoint-up(lg) {
            display: block;
            margin-right: remX(85px);
        }
    }

    .cta-box {
        &:not(:last-child) {
            margin-bottom: remX(50px);

            @include media-breakpoint-up(md) {
                margin-bottom: remX(80px);
            }
        }

        &__icon {
            color: $light-blue-color;
            font-size: remX(36px);
        }

        &__link {
            margin-top: remX(28px);

            .link-btn {
                padding: 0;
                display: inline;
                background-color: transparent;
                border-radius: 0;

                &:not([href^="tel:"]) {
                    @include link-underline(#fff, $light-blue-color);
                }

                &[href^="tel:"] {
                    color: $light-blue-color;
                    font-size: remX(24px);
                    transition: color $transition-time;

                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }

    h2 {
        margin-bottom: remX(26px);
        width: 100%;
        color: inherit;

        @include media-breakpoint-up(md) {
            margin-bottom: remX(45px);
        }
    }
}
